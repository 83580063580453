
  import { Action, Getter } from "vuex-class";
  import { Component, Mixins, Watch, Ref } from "vue-property-decorator";
  import { FormBase, FormInput, FormCheckbox, FormTextarea, FormSelect, FormMoney, FormPhone, FormError, FormNationalInsuranceNumber, FormSubmit } from "@/components/forms";
  const scrollIntoView = require("scroll-into-view");
  import countries from "@/assets/json/countries.json";
  const cloneDeep = require("lodash/cloneDeep");
  import { mask } from "vue-the-mask";
  import type { PublicOffice } from "@/store/modules/public-office.store";
  import { ValidationProvider } from "vee-validate";
  import { titleOptions, suspensiveConditionOptions, isoCountriesCodes } from "@/utils/select-options";
  import { HasNotaryOptions } from "@/mixins/has-notary-options";
  import { format } from "fecha";

  @Component({
    components: {
      InputRadio: () => import("@/components/forms/property-radio.vue"),
      FormBase,
      FormInput,
      FormCheckbox,
      FormTextarea,
      FormNationalInsuranceNumber,
      FormSelect,
      FormPhone,
      FormMoney,
      FormError,
      FormSubmit,
      ValidationProvider,
    },
    directives: { mask },
  })
  export default class PageBidForm extends Mixins(HasNotaryOptions) {
    @Getter("activity/viewing") activity!: Activity;
    @Getter("public-office/viewing") selectedOffice!: PublicOffice;

    @Action("activity/readPublic") readPublicActivity!: (public_id: string) => Promise<Activity>;
    @Action("bid/publicCreate") createPublicBid!: (payload: any) => Promise<any>;
    @Action("bid/createSignRequest") createSignRequest!: (payload: BidSignPayload) => Promise<Document>;
    @Action("public-office/read") readPublicOffice!: (payload: { office_id?: number; setStyling?: boolean }) => Promise<PublicOffice>;

    @Ref() form!: FormClass;

    isCompany = false;

    loaded = false;

    sent = false;

    countries = countries;

    titleOptions = titleOptions;

    submitStatus = 0;

    suspensiveConditionOptions = suspensiveConditionOptions;

    isoCountriesCodes = isoCountriesCodes;

    notesToAccept: Array<{ label: string; value: number; accepted: boolean }> = [];

    payload: any = {
      activity_id: "",
      amount: null,
      company_details: null,
      electricity_inspection_conform_accepted: null,
      struck_by_red_line_accepted: null,
      habitable_after_deed_period_accepted: null,
      fuel_oil_tank_compliant_accepted: null,
      asbestos_present_accepted: null,
      epc_accepted: null,
      notes_accepted: null,
      visible: false,
      signing_details: {
        expiration_date: "",
        notary_info: "",
        notary_city: "",
        remarks: "",
      },
      representatives: [],
      suspensive_conditions_weeks: null,
      suspensive_conditions: "",
    };

    suspensiveConditions: boolean | null = false;

    async mounted() {
      this.loaded = false;

      this.getNotaryOptions();

      try {
        const activity = await this.readPublicActivity(this.$route.params.id);

        if (!activity.property_address) {
          throw new Error("Activity has no property address");
        }

        if (activity.property_sale && activity.property_sale.offer_from) {
          this.payload.amount = activity.property_sale.offer_from;
        }

        await this.readPublicOffice({ office_id: Number(activity.office_id), setStyling: true });

        this.addRepresentative();

        this.payload.activity_id = activity.public_id;
        // @ts-ignore
        this.payload.signing_details.expiration_date = format(new Date().setDate(new Date().getDate() + 7), "YYYY-MM-DD");

        if (activity.conditions) {
          this.notesToAccept = activity.conditions.notes.map((n, index) => {
            return {
              label: n,
              value: index,
              accepted: false,
            };
          });
        }

        this.loaded = true;
      } catch (e) {
        this.$router.push({ name: "error", params: { code: "404" } });
      }
    }

    setDevPayload() {
      this.payload.company_details = null;
      this.payload.electricity_inspection_conform_accepted = null;
      this.payload.struck_by_red_line_accepted = null;
      this.payload.habitable_after_deed_period_accepted = null;
      this.payload.fuel_oil_tank_compliant_accepted = null;
      this.payload.asbestos_present_accepted = null;
      this.payload.epc_accepted = null;
      this.payload.notes_accepted = null;
      this.payload.visible = false;
      this.payload.signing_details = {
        expiration_date: "2024-03-31",
        notary_info: "Act & Lex",
        notary_city: "Neerpelt",
        remarks: "Dit is een test",
      };
      this.payload.representatives = [
        {
          title: "mr",
          first_name: "Demo",
          last_name: "Bieder",
          national_register_number: "12.31.23-123-12",
          birth_date: "1990-01-01",
          birth_place: "Peer",
          birth_country: "BE",
          street: "Kerkstraat",
          additional: "",
          country: "BE",
          city: "Peer",
          zip: "3900",
          number: "1",
          phone: "0473 84 99 37",
          email: "fr+bod@salamander.be",
        },
      ];
      this.payload.suspensive_conditions_weeks = null;
      this.payload.suspensive_conditions = "";
    }

    async submit(form: FormClass) {
      const payload: any = cloneDeep(this.payload);

      //@ts-ignore
      payload.amount = typeof this.payload.amount === "string" ? parseFloat(this.payload.amount!.replaceAll(",", "")) : this.payload.amount;

      payload.notes_accepted = this.notesToAccept.length === 0 ? true : this.notesToAccept.every((n) => n.accepted);

      payload.representatives.forEach((r: any) => {
        r.phone = r.phone.replace(" ", "");
      });

      if (!payload.notes_accepted) {
        form.reset();
        return;
      }

      try {
        this.submitStatus = 1;

        const bid = await this.createPublicBid(payload);

        this.submitStatus = 2;

        await this.createSignRequest({ id: bid.id, ...this.payload.signing_details });

        this.submitStatus = 3;

        this.$router.push({ name: "bid-sign", params: { id: bid.id } });
      } catch (errorResponse: any) {
        this.$errorResponse = errorResponse;
        form.reset();
      }
    }

    handleInvalidSubmit() {
      this.$toast.open({ message: `${this.form.allErrors!.length} veld${this.form.allErrors!.length > 1 ? "en zijn" : " is"}  onjuist ingevuld` as string, type: "error" });

      this.scrollToFirstError();
    }

    addRepresentative() {
      this.payload.representatives!.push({
        title: "",
        first_name: "",
        last_name: "",
        national_register_number: "",
        birth_date: "",
        birth_place: "",
        birth_country: "BE",
        street: "",
        additional: "",
        country: "BE",
        city: "",
        zip: "",
        number: "",
        phone: "",
        email: "",
      });
    }

    removeRepresentative(index: number) {
      this.payload.representatives!.splice(index, 1);
    }

    scrollToFirstError() {
      const invalidElems = document.querySelectorAll(".invalid");

      if (invalidElems.length) {
        scrollIntoView(invalidElems[0]);
      }
    }

    get hasSellerConditions() {
      // @ts-ignore
      return (
        this.activity &&
        this.activity.conditions &&
        Object.values(this.activity.conditions).some((c: any) => {
          // if not null or not empty array
          return c !== null && (Array.isArray(c) ? c.length !== 0 : true);
        })
      );
    }

    get hasAcceptedNotes() {
      // @ts-ignore
      return this.notesToAccept.length !== 0 ? this.notesToAccept.every((n) => n.accepted) : true;
    }

    get submitStatusMessage() {
      if (this.submitStatus <= 0) {
        return null;
      }

      switch (this.submitStatus) {
        case 1:
          return "Bod wordt geregistreerd...";
        case 2:
          return "Aankoopbelofte wordt doorgestuurd naar Connective...";
        case 3:
          return "Document wordt opgevraagd bij Connective...";
        default:
          return null;
      }
    }

    @Watch("isCompany")
    handleIsCompanyChanged(isCompany: boolean) {
      if (isCompany) {
        this.payload.company_details = {
          name: "",
          vat_number: "",
          corporate_headquarters: "",
        };
      } else {
        this.payload.company_details = null;
      }
    }
  }
